import { Logger } from '@je-pc/utils'
import { LOADING_SOURCE_CODE } from 'single-spa'
import { IS_PRODUCTION } from './constants'

export default new Logger({
  packageName: '@je-pc/root-config',
  release: '1.0.3',
  sentryOptions: {
    ignoreErrors: [new RegExp(LOADING_SOURCE_CODE, 'gi')],
    tracesSampleRate: IS_PRODUCTION ? 0.05 : 0,
  },
})
