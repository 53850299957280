import { GlobalConfigService } from '@rmp/services'

export class RoutesConfigManager {
  #routes
  #redirects

  constructor(routes, redirects) {
    this.#routes = routes
    this.#redirects = redirects
  }

  #isEnabled(item) {
    const isEnabled = () => {
      if (!item.hasOwnProperty('isEnabled')) return true

      return item.isEnabled
    }

    const isEnabledForCountry = () => {
      const currentCountry = GlobalConfigService.getConfigs()?.country
      if (
        !currentCountry ||
        !item.hasOwnProperty('countries') ||
        !Array.isArray(item.countries)
      )
        return true

      return item.countries.includes(currentCountry)
    }

    return isEnabled() && isEnabledForCountry()
  }

  #getRoutes(routes) {
    if (!Array.isArray(routes) || !routes.length) return

    return routes.reduce((result, route) => {
      if (!this.#isEnabled(route)) return result

      const routes = this.#getRoutes(route.routes)
      if (routes) route.routes = routes

      delete route.isEnabled
      delete route.countries

      result.push(route)

      return result
    }, [])
  }

  #getRedirects(redirects) {
    return redirects.reduce((result, redirect) => {
      if (this.#isEnabled(redirect)) {
        result[redirect.from] = redirect.to
      }

      return result
    }, {})
  }

  getRoutesConfig() {
    return {
      routes: this.#getRoutes(this.#routes),
      redirects: this.#getRedirects(this.#redirects),
    }
  }
}
