import { getBooleanFeatureFlag } from '../utils/featureFlags'
import { IS_TOP_RANK_ENABLED } from '../constants'

export const getMainRoutes = () => {
  const isUserManagementEnabled = getBooleanFeatureFlag(
    'enable_user_management'
  )

  return [
    {
      type: 'route',
      path: 'home',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [{ type: 'application', name: '@rmp/dashboard' }],
    },
    {
      type: 'route',
      path: 'analytics',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [{ type: 'application', name: '@rmp/analytics' }],
    },
    {
      type: 'route',
      path: 'partner-status',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [{ type: 'application', name: '@je-pc/partner-status' }],
    },
    {
      type: 'route',
      path: 'account',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [{ type: 'application', name: '@je-pc/account' }],
    },
    {
      type: 'route',
      path: 'account-settings',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [{ type: 'application', name: '@je-pc/account' }],
    },
    {
      type: 'route',
      path: 'reviews',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [{ type: 'application', name: '@je-pc/reviews' }],
    },
    {
      type: 'route',
      path: 'help',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [
        { type: 'application', name: '@je-pc/business-help' },
        { type: 'application', name: '@pha/ph-business-essentials' },
      ],
    },
    {
      type: 'route',
      path: 'live-reporting',
      countries: ['nz'],
      routes: [
        {
          type: 'application',
          name: '@je-pc/live-reporting',
        },
      ],
    },
    {
      type: 'route',
      path: 'performance',
      countries: ['nz'],
      routes: [
        {
          type: 'application',
          name: '@je-pc/performance',
        },
      ],
    },

    //orders-and-invoices
    {
      type: 'route',
      path: 'orders-and-invoices',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [
        {
          type: 'route',
          path: 'invoices',
          routes: [{ type: 'application', name: '@je-pc/invoices' }],
        },
        {
          type: 'route',
          path: 'order-history',
          routes: [{ type: 'application', name: '@je-pc/order-history' }],
        },
        {
          type: 'route',
          path: 'customer-refunds',
          routes: [{ type: 'application', name: '@je-pc/restaurantclaimsweb' }],
        },
      ],
    },

    //menu
    {
      type: 'route',
      path: 'menu',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [
        {
          type: 'route',
          path: 'item-availability',
          routes: [{ type: 'application', name: '@je-pc/item-availability' }],
        },
        {
          type: 'route',
          path: 'edit',
          routes: [{ type: 'application', name: '@je-pc/menus' }],
        },
      ],
    },

    //settings
    {
      type: 'route',
      path: 'settings',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [
        {
          type: 'route',
          path: 'opening-hours',
          routes: [{ type: 'application', name: '@je-pc/opening-hours' }],
        },
        {
          type: 'route',
          path: 'order-time',
          routes: [{ type: 'application', name: '@je-pc/order-time' }],
        },
        {
          type: 'route',
          path: 'account',
          routes: [{ type: 'application', name: '@je-pc/account' }],
        },
        {
          type: 'route',
          path: 'delivery-charges',
          routes: [{ type: 'application', name: '@je-pc/delivery-charges' }],
        },
        {
          type: 'route',
          path: 'delivery-management',
          routes: [{ type: 'application', name: '@je-pc/delivery-management' }],
        },
        {
          type: 'route',
          path: 'restaurant-details/media',
          routes: [{ type: 'application', name: '@rmp/partner-media' }],
        },
        {
          type: 'route',
          path: 'restaurant-details',
          routes: [{ type: 'application', name: '@je-pc/restaurant-details' }],
        },
      ],
    },

    //partner-chatbot
    {
      type: 'route',
      path: 'support/chatbot',
      countries: ['uk'],
      routes: [
        {
          type: 'application',
          name: '@pha/partner-chatbot',
        },
      ],
    },

    //marketing
    {
      type: 'route',
      path: 'marketing',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [
        {
          type: 'route',
          path: 'promoted-placement',
          routes: [
            {
              type: 'application',
              name: '@je-pc/promoted-placement',
            },
          ],
        },
        {
          type: 'route',
          path: 'stamp-cards',
          routes: [
            {
              type: 'application',
              name: '@je-pc/marketing-stampcards',
            },
          ],
        },
        {
          type: 'route',
          path: 'offers',
          routes: [
            { type: 'application', name: '@je-pc/offers' },
            { type: 'application', name: '@je-pc/marketing-offers' },
          ],
        },
        {
          type: 'route',
          path: 'toprank',
          isEnabled: IS_TOP_RANK_ENABLED,
          routes: [
            {
              type: 'application',
              name: '@je-pc/opacampaignmanagementweb',
            },
          ],
        },
      ],
    },

    //account-settings
    {
      type: 'route',
      path: 'ph-account-settings',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      routes: [
        {
          type: 'application',
          name: '@pha/ph-account-settings',
        },
      ],
    },

    {
      type: 'route',
      path: 'user-management',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      isEnabled: isUserManagementEnabled,
      routes: [
        {
          type: 'application',
          name: '@pha/ph-user-management',
        },
      ],
    },

    {
      type: 'route',
      path: 'partner-invitation',
      countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
      isEnabled: isUserManagementEnabled,
      routes: [
        {
          type: 'application',
          name: '@pha/um-partner-invitation',
        },
      ],
    },
  ]
}
