import {
  registerApplication,
  start,
  addErrorHandler,
  setBootstrapMaxTime,
  setMountMaxTime,
  getAppStatus,
  LOAD_ERROR,
} from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout'

import {
  GlobalConfigService,
  GlobalLanguageService,
  GlobalFeatureManagementService,
  GlobalTrackingService,
  GlobalCookieService,
  UsabillaService,
  Platform,
} from '@rmp/services'
import { setupGlobalStyles } from '@rmp/styleguide'
import { AuthClient, UserClient } from '@rmp/auth-client'

import { RoutesConfigManager } from './routes-config'

import { getRedirects } from './routes-config/redirects'
import { getRoutes } from './routes-config/routes'
import { TENANT, ENVIRONMENT, TEAM } from './constants'
import logger from './logger'

const BOOTSTRAP_MAX_TIME = 10000

async function startSpa() {
  const routesConfig = new RoutesConfigManager(
    await getRoutes(),
    await getRedirects()
  ).getRoutesConfig()
  const routes = constructRoutes(routesConfig)

  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name)
    },
  })
  constructLayoutEngine({ routes, applications })
  applications.forEach(registerApplication)
  start({
    urlRerouteOnly: true,
  })
}

async function setupPlatform() {
  try {
    const initialLanguage = GlobalLanguageService.getInitialLanguage(TENANT)

    await GlobalConfigService.init({
      platform: Platform.je,
      country: TENANT,
      lang: initialLanguage,
      environment: ENVIRONMENT,
      tenant: TENANT,
      team: TEAM,
    })
    await AuthClient.init({
      configSubscriber: GlobalConfigService.subscribeToConfigs,
    })
    await GlobalFeatureManagementService.init()
    await GlobalCookieService.init()
    await GlobalTrackingService.init()
    UsabillaService.init()

    setupGlobalStyles()
    UserClient.subscribeToUserInfo(() => setupGlobalStyles())
  } catch (error) {
    logger.error(error)
  }
}

async function bootstrapApp() {
  setBootstrapMaxTime(BOOTSTRAP_MAX_TIME)
  setMountMaxTime(BOOTSTRAP_MAX_TIME)

  window.addEventListener('single-spa:first-mount', () => {
    document.querySelector('#root-spinner')?.remove()
    document.querySelector('#root-error-container')?.remove()
  })

  await setupPlatform()
  await startSpa()

  addErrorHandler((error) => {
    // https://single-spa.js.org/docs/api/#handling-load_error-status-to-retry-module
    if (getAppStatus(error.appOrParcelName) === LOAD_ERROR) {
      System.delete(System.resolve(error.appOrParcelName))
    }
    logger.error(error)
  })
}

bootstrapApp()
